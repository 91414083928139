@font-face {
  font-family: "NeuzeitGroteskRegular";
  src: local("NeuzeitGroteskRegular"),
    url(../fonts/NeuzeitGroteskRegular.ttf) format("truetype"),
    url(../fonts/NeuzeitGroteskRegular.woff) format("woff"),
    url(../fonts/NeuzeitGroteskRegular.eot) format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Medium";
  src: local("Avenir-Medium"), url(../fonts/AvenirMedium.ttf) format("truetype"),
    url(../fonts/AvenirMedium.woff) format("woff"),
    url(../fonts/AvenirMedium.eot) format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Bold";
  src: local("Avenir-Bold"), url(../fonts/AvenirBold.ttf) format("truetype"),
    url(../fonts/AvenirBold.woff) format("woff"),
    url(../fonts/AvenirBold.eot) format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Crimson";
  src: local("Crimson"),
    url(../fonts/CrimsonText-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "NeuzeitGroteskRegular";
  color: #052d3f;
}

h1 {
  font-family: "Avenir-Bold";
}

h2,
h3,
h4,
h5 {
  font-family: "Avenir-Medium";
}

p {
  padding-bottom: 24px;
}

.logo {
  font-family: "Crimson";
}
